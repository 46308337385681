






























import { Component, Vue, Model, Emit, Prop } from 'vue-property-decorator'
@Component
export default class conflictModal extends Vue {
  @Model('change', { type: Boolean }) private readonly visible!: boolean
  // 接受父组件的值

  @Prop({ default: () => [] }) private data!: Array<any>

  private loading: boolean = false

  @Emit('change')
  private closeModal(): boolean {
    return false
  }

  @Emit('confirm')
  private confirm(operation): any {
    this.closeModal()
    return operation
  }
}
