
















































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import filterGroup from '@/components/filterGroup'
import FlexTooltip from '@/components/FlexTooltip.vue'
import {
  CourseController,
  DropDownController,
  MonthlyGradeController,
} from '@/services/request.service'
import debounce from 'lodash/debounce'
import { filterData, Debounce } from '@/utils/utils'
import { exportXlsx, writeFile } from '@/utils/xlsx'
import moment from 'moment'
import cloneDeep from 'lodash/cloneDeep'

@Component({
  components: {
    filterGroup,
    FlexTooltip,
  },
})
export default class Assessment extends Vue {
  private filter: any = {
    schoolYear: '',
    monthlyGradePeriodId: undefined,
    courseId: [],
  }
  private schoolYearList: any = []
  private courseClasses: Array<any> = []
  private monthlyGradePeriods: Array<any> = []
  private filterData = filterData

  private gradeList: Array<any> = []
  private effortGrades: Array<any> = []
  private attainmentGrades: Array<any> = []

  private data: Array<any> = []
  private studentGradesInfo: Array<any> = []
  private loading: boolean = false
  private exportLoading: boolean = false
  private manageForm: any = {
    grade: undefined,
  }
  private windowH: any = 710
  private drawerVisible = false
  private updateLoading = false
  private currentAssessment: any = {}
  private nextVisible = false
  private lastVisible = false
  private studentMenuKey: Array<any> = []
  private gradePeriodSetting: any = {}

  private form: any = {
    date: undefined,
  }
  private moment = moment

  private get operationAuths(): any {
    return this.$store.state.operationAuths
  }

  private get locale(): any {
    return this.$store.state.locale
  }

  private get ratio(): any {
    return this.$store.state.ratio || 1
  }

  private get columns(): any {
    return [
      {
        key: 'studentName',
        dataIndex: 'studentName',
        title: this.$t('common.student'),
        ellipsis: true,
        width: 250,
        scopedSlots: { customRender: 'studentName' },
      },
      {
        key: 'studentNum',
        dataIndex: 'studentNum',
        title: this.$t('common.studentId'),
        width: 150,
      },
      this.gradePeriodSetting.ea && {
        title: 'Effort',
        dataIndex: 'effort.value',
        key: 'effort',
        width: 170,
        // slots: { title: 'setAll' },
        scopedSlots: {
          filterDropdown: 'setAll',
          filterIcon: 'batchButton',
          customRender: 'effort',
        },
        onFilterDropdownVisibleChange: visible => (visible ? this.getGradeList('effort') : false),
        visible: false,
      },
      this.gradePeriodSetting.ea && {
        title: 'Attainment',
        dataIndex: 'attainment.value',
        key: 'attainment',
        width: 170,
        // slots: { title: 'setAll' },
        scopedSlots: {
          filterDropdown: 'setAll',
          filterIcon: 'batchButton',
          customRender: 'attainment',
        },
        onFilterDropdownVisibleChange: visible =>
          visible ? this.getGradeList('attainment') : false,
        visible: false,
      },
      this.gradePeriodSetting.ep && {
        title: 'Exam Percentage',
        key: 'examPercentage',
        dataIndex: 'examPercentage',
        scopedSlots: {
          customRender: 'examPercentage',
        },
        width: 150,
      },
      this.gradePeriodSetting.courseTeacher && {
        title: 'Comments',
        dataIndex: 'comments',
        key: 'comments',
        scopedSlots: {
          customRender: 'comments',
        },
      },
    ].filter(item => item)
  }

  private ifEffort(column): any {
    return column.key.split('_')[0].toLowerCase() === 'effort'
  }

  private async getDropDownInfo(): Promise<any> {
    let schoolYearList = await DropDownController.getSchoolYearRuleList()
    this.schoolYearList = schoolYearList.data
    this.$set(this.filter, 'schoolYear', schoolYearList.data[0].key)
    await this.changeSchoolYear()
  }

  private getGradeDropDown(): void {
    Promise.all([
      MonthlyGradeController.getGradeLevels('effort'),
      MonthlyGradeController.getGradeLevels('attainment'),
    ]).then(res => {
      this.effortGrades = res[0].data
      this.attainmentGrades = res[1].data
      // this.effortGrades.push({
      //   enValue: '--',
      //   value: '--',
      //   key: 0,
      // })
      // this.attainmentGrades.push({
      //   enValue: '--',
      //   value: '--',
      //   key: 0,
      // })
    })
  }

  private async changeSchoolYear(): Promise<any> {
    this.$set(this.filter, 'courseId', [])
    this.data = []
    this.courseClasses = []
    this.monthlyGradePeriods = []
    this.studentGradesInfo = []
    this.getCourseClasses()
  }

  private getCourseClasses(): void {
    CourseController.getCoursesCascadeBySchoolYear(this.filter.schoolYear).then(res => {
      if (res.data.length) {
        this.courseClasses = res.data.map(item => {
          return {
            ...item,
            subOptions: item.subOptions.map(it => {
              const { subOptions, ...newIt } = it
              return newIt
            }),
          }
        })
        this.$set(this.filter, 'courseId', [res.data[0].key, res.data[0].subOptions[0].key])
      }
      this.getGradePeriods()
    })
  }

  private getGradePeriods(): void {
    this.monthlyGradePeriods = []
    this.$set(this.filter, 'monthlyGradePeriodId', undefined)
    if (!this.filter.courseId[1]) return
    MonthlyGradeController.getGradePeriodsByCourseId(
      this.filter.schoolYear,
      this.filter.courseId[1]
    ).then(res => {
      if (res.data.length) {
        this.monthlyGradePeriods = res.data.filter(item => {
          const { courseTeacher, deputyHead, ea, ep, headTeacher, tutor } = item.extraValue
          return courseTeacher || deputyHead || ea || ep || headTeacher || tutor
        })
        this.$set(this.filter, 'monthlyGradePeriodId', this.monthlyGradePeriods[0].key)
        this.gradePeriodSetting = res.data[0].extraValue
      }
      this.getData()
    })
  }

  private getData(): void {
    this.studentGradesInfo = []
    if (this.loading || !this.filter.courseId[1] || !this.filter.monthlyGradePeriodId) return
    this.loading = true
    MonthlyGradeController.getMonthlyGradeByGPId(
      this.filter.monthlyGradePeriodId,
      this.filter.courseId[1]
    )
      .then(res => {
        this.data = res.data.map(item => {
          return {
            ...item,
            iconVisible: false,
          }
        })
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => {
        this.loading = false
      })
  }

  private getGradeList(type): void {
    this.gradeList = []
    this.$set(this.manageForm, 'grade', undefined)
    MonthlyGradeController.getGradeLevels(type)
      .then(res => {
        this.gradeList = res.data
        // this.$set(this.gradeList, )
        // this.gradeList.push({
        //   enValue: '--',
        //   value: '--',
        //   key: 0,
        // })
      })
      .catch(err => console.error(err))
  }

  private batchMark(confirm, column): void {
    const form = this.$refs.manageForm as any
    form.validate(valid => {
      if (valid) {
        const courseId = this.filter.courseId[1]
        const gradePeriodId = this.filter.monthlyGradePeriodId
        const type = column.key.split('_')[0].toLowerCase()
        const level = this.manageForm.grade ? this.manageForm.grade : undefined
        const studentId = this.data.map(item => item.studentId)
        MonthlyGradeController.updateGradeByCourse(courseId, gradePeriodId, type, studentId, level)
          .then(res => {
            this.$message.success(this.$tc('common.saveSuccess'))
            this.getData()
            // const grade = level ? this.gradeList.find(item => item.key === level).value : ''
            // this.data.forEach(record => {
            //   record[column.key] = grade
            // })
            confirm()
          })
          .catch(err => console.error(err))
      }
    })
  }

  private singleMark(column, record, key): void {
    const courseId = this.filter.courseId[1]
    const gradePeriodId = this.filter.monthlyGradePeriodId
    const studentId = record.studentId
    MonthlyGradeController.updateStudentGrade({
      courseId,
      gradePeriodId,
      studentId,
      [column.key]: key,
    })
      .then(res => {
        this.$message.success(this.$tc('common.saveSuccess'))
        // this.getData()

        record[column.key] = this.ifEffort(column)
          ? this.effortGrades.find(item => item.key === key).value
          : this.attainmentGrades.find(item => item.key === key).value
      })
      .catch(err => console.error(err))
  }

  private fetchData = debounce(() => {
    this.getData()
  }, 500)

  private mounted(): any {
    setTimeout(() => {
      this.windowH = (this.$refs.assessment as Element)?.getBoundingClientRect().height || 710
    }, 2000)

    window.addEventListener('resize', this.onWindowResize)
    this.$once('hook:beforeDestory', () => {
      window.removeEventListener('resize', this.onWindowResize)
    })
  }

  @Debounce(500)
  private onWindowResize(): void {
    this.windowH = (this.$refs.assessment as Element)?.getBoundingClientRect().height || 710
    console.log(this.windowH, 'windowH')
  }

  private created(): void {
    this.getDropDownInfo()
    this.getGradeDropDown()
  }

  private get excelColumns(): Array<any> {
    return [
      {
        dataIndex: 'lastName',
        title: this.$t('myClass.student.lastName'),
        width: 10,
      },
      {
        dataIndex: 'enName',
        title: this.$t('common.enName'),
        width: 20,
      },
      {
        dataIndex: 'studentNum',
        title: this.$t('common.studentId'),
        width: 20,
      },
      {
        dataIndex: 'gender',
        title: this.$t('addressBook.gender'),
        width: 15,
      },
      {
        dataIndex: 'grade',
        title: this.$t('common.grade'),
        width: 10,
      },
      {
        dataIndex: 'formTime',
        title: this.locale === 'zh' ? '学院' : 'FormTime',
        width: 20,
      },

      {
        dataIndex: 'chineseEffort',
        title: 'Chinese Effort',
        width: 5,
      },
      {
        dataIndex: 'chineseAttainment',
        title: 'Chinese Attainment',
        width: 5,
      },
      {
        dataIndex: 'englishEffort',
        title: 'English Effort',
        width: 5,
      },
      {
        dataIndex: 'englishAttainment',
        title: 'English Attainment',
        width: 5,
      },
      {
        dataIndex: 'mathematicsEffort',
        title: 'Mathematics Effort',
        width: 5,
      },
      {
        dataIndex: 'mathematicsAttainment',
        title: 'Mathematicss Attainment',
        width: 5,
      },
      {
        dataIndex: 'scienceEffort',
        title: 'Science Effort',
        width: 5,
      },
      {
        dataIndex: 'scienceAttainment',
        title: 'Science Attainment',
        width: 5,
      },
      {
        dataIndex: 'biologyEffort',
        title: 'Biology Effort',
        width: 5,
      },
      {
        dataIndex: 'biologyAttainment',
        title: 'Biology Attainment',
        width: 5,
      },
      {
        dataIndex: 'chemistryEffort',
        title: 'Chemistry Effort',
        width: 5,
      },
      {
        dataIndex: 'chemistryAttainment',
        title: 'Chemistry Attainment',
        width: 5,
      },
      {
        dataIndex: 'physicsEffort',
        title: 'Physics Effort',
        width: 5,
      },
      {
        dataIndex: 'physicsAttainment',
        title: 'Physics Attainment',
        width: 5,
      },
      {
        dataIndex: 'geographyEffort',
        title: 'Geography Effort',
        width: 5,
      },
      {
        dataIndex: 'geographyAttainment',
        title: 'Geography Attainment',
        width: 5,
      },
      {
        dataIndex: 'historyEffort',
        title: 'History Effort',
        width: 5,
      },
      {
        dataIndex: 'historyAttainment',
        title: 'History Attainment',
        width: 5,
      },
      {
        dataIndex: 'technologyEffort',
        title: 'Technology Effort',
        width: 5,
      },
      {
        dataIndex: 'technologyAttainment',
        title: 'Technology Attainment',
        width: 5,
      },
      {
        dataIndex: 'sOLEffort',
        title: 'SOL Effort',
        width: 5,
      },
      {
        dataIndex: 'sOLAttainment',
        title: 'SOL Attainment',
        width: 5,
      },
      {
        dataIndex: 'morallityEffort',
        title: 'Morallity Effort',
        width: 5,
      },
      {
        dataIndex: 'morallityAttainment',
        title: 'Morality Attainment',
        width: 5,
      },
      {
        dataIndex: 'iCTEffort',
        title: 'ICT Effort',
        width: 5,
      },
      {
        dataIndex: 'iCTAttainment',
        title: 'ICT Attainment',
        width: 5,
      },

      {
        dataIndex: 'artEffort',
        title: 'Art Effort',
        width: 5,
      },
      {
        dataIndex: 'artAttainment',
        title: 'Art Attainment',
        width: 5,
      },
      {
        dataIndex: 'dramaEffort',
        title: 'Drama Effort',
        width: 5,
      },
      {
        dataIndex: 'dramaAttainment',
        title: 'Drama Attainment',
        width: 5,
      },
      {
        dataIndex: 'musicEffort',
        title: 'Music Effort',
        width: 5,
      },
      {
        dataIndex: 'musicAttainment',
        title: 'Music Attainment',
        width: 5,
      },
      {
        dataIndex: 'physicalEducationPhysicalEffort',
        title: 'Physical Education Effort',
        width: 5,
      },
      {
        dataIndex: 'physicalEducationPhysicalAttainment',
        title: 'Physical Education Attainment',
        width: 5,
      },
      {
        dataIndex: 'economicsEffort',
        title: 'Economics Effort',
        width: 5,
      },
      {
        dataIndex: 'economicsAttainment',
        title: 'Economics Attainment',
        width: 5,
      },
      {
        dataIndex: 'gCSEComputingGCSEEffort',
        title: 'GCSE Computing Effort',
        width: 5,
      },
      {
        dataIndex: 'gCSEComputingGCSEAttainment',
        title: 'GCSE Computing Attainment',
        width: 5,
      },
    ]
  }

  private exportAssessment(): void {
    this.exportLoading = true
    MonthlyGradeController.getMonthlyGradeExport(this.filter.monthlyGradePeriodId)
      .then(async res => {
        let result = res.data.map(item => {
          let {
            lastName,
            enName,
            studentNum,
            gender,
            grade,
            formTime,
            // sortNum,
            chineseEffort,
            chineseAttainment,
            englishEffort,
            englishAttainment,
            mathematicsEffort,
            mathematicsAttainment,
            scienceEffort,
            scienceAttainment,
            biologyEffort,
            biologyAttainment,
            chemistryEffort,
            chemistryAttainment,
            physicsEffort,
            physicsAttainment,
            geographyEffort,
            geographyAttainment,
            historyEffort,
            historyAttainment,
            technologyEffort,
            technologyAttainment,
            sOLEffort,
            sOLAttainment,
            morallityEffort,
            morallityAttainment,
            iCTEffort,
            iCTAttainment,
            artEffort,
            artAttainment,
            dramaEffort,
            dramaAttainment,
            musicEffort,
            musicAttainment,
            economicsEffort,
            economicsAttainment,
            gCSEComputingGCSEEffort,
            gCSEComputingGCSEAttainment,
            physicalEducationPhysicalEffort,
            physicalEducationPhysicalAttainment,
          } = item
          return {
            lastName,
            enName,
            studentNum,
            gender: this.$t(`common.gender.${gender}`),
            grade: grade ? grade : '',
            formTime: formTime ? formTime : '',
            chineseEffort: Number(chineseEffort) || chineseEffort || '',
            chineseAttainment: chineseAttainment ? chineseAttainment : '',
            englishEffort: Number(englishEffort) || englishEffort || '',
            englishAttainment: englishAttainment ? englishAttainment : '',
            mathematicsEffort: Number(mathematicsEffort) || mathematicsEffort || '',
            mathematicsAttainment: mathematicsAttainment ? mathematicsAttainment : '',
            scienceEffort: Number(scienceEffort) || scienceEffort || '',
            scienceAttainment: scienceAttainment ? scienceAttainment : '',
            biologyEffort: Number(biologyEffort) || biologyEffort || '',
            biologyAttainment: biologyAttainment ? biologyAttainment : '',
            chemistryEffort: Number(chemistryEffort) || chemistryEffort || '',
            chemistryAttainment: chemistryAttainment ? chemistryAttainment : '',
            physicsEffort: Number(physicsEffort) || physicsEffort || '',
            physicsAttainment: physicsAttainment ? physicsAttainment : '',
            geographyEffort: Number(geographyEffort) || geographyEffort || '',
            geographyAttainment: geographyAttainment ? geographyAttainment : '',
            historyEffort: Number(historyEffort) || historyEffort || '',
            historyAttainment: historyAttainment ? historyAttainment : '',
            technologyEffort: Number(technologyEffort) || technologyEffort || '',
            technologyAttainment: technologyAttainment ? technologyAttainment : '',
            sOLEffort: Number(sOLEffort) || sOLEffort || '',
            sOLAttainment: sOLAttainment ? sOLAttainment : '',
            morallityEffort: Number(morallityEffort) || morallityEffort || '',
            morallityAttainment: morallityAttainment ? morallityAttainment : '',
            iCTEffort: Number(iCTEffort) || iCTEffort || '',
            iCTAttainment: iCTAttainment ? iCTAttainment : '',
            artEffort: Number(artEffort) || artEffort || '',
            artAttainment: artAttainment ? artAttainment : '',
            dramaEffort: Number(dramaEffort) || dramaEffort || '',
            dramaAttainment: dramaAttainment ? dramaAttainment : '',
            musicEffort: Number(musicEffort) || musicEffort || '',
            musicAttainment: musicAttainment ? musicAttainment : '',
            economicsEffort: Number(economicsEffort) || economicsEffort || '',
            economicsAttainment: economicsAttainment ? economicsAttainment : '',
            gCSEComputingGCSEEffort:
              Number(gCSEComputingGCSEEffort) || gCSEComputingGCSEEffort || '',
            gCSEComputingGCSEAttainment: gCSEComputingGCSEAttainment
              ? gCSEComputingGCSEAttainment
              : '',
            physicalEducationPhysicalEffort:
              Number(physicalEducationPhysicalEffort) || physicalEducationPhysicalEffort || '',
            physicalEducationPhysicalAttainment: physicalEducationPhysicalAttainment
              ? physicalEducationPhysicalAttainment
              : '',
          }
        })

        const file = exportXlsx(this.excelColumns, result)
        const worksheet = file.getWorksheet(1)

        worksheet.eachRow(row => {
          row.height = 22
          row.eachCell(cell => {
            cell.border = {
              top: { style: 'thin', color: { argb: 'FF000000' } },
              left: { style: 'thin', color: { argb: 'FF000000' } },
              bottom: { style: 'thin', color: { argb: 'FF000000' } },
              right: { style: 'thin', color: { argb: 'FF000000' } },
            }
            cell.style.alignment = { vertical: 'middle', horizontal: 'center' }
          })
        })
        const row = worksheet.getRow(1)
        row.height = 190
        row.eachCell((cell, colNumber) => {
          if (colNumber > 6) {
            cell.style.alignment = { textRotation: 90 }
          }
        })

        const monthlyGradePeriod = this.monthlyGradePeriods.find(
          item => item.key === this.filter.monthlyGradePeriodId
        )

        await writeFile(
          file,
          `Assessment Date of Monthly Report ${monthlyGradePeriod.enValue ||
            monthlyGradePeriod.value}.xlsx`
        )
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        this.exportLoading = false
      })
  }

  private customRow(record, index): any {
    return {
      on: {
        click: e => {
          if (this.gradePeriodSetting.status === 'lock') return
          this.setDetail(record, index, false)
          this.drawerVisible = true
        },
        mouseenter: e => {
          if (this.gradePeriodSetting.status === 'lock') return
          record.iconVisible = true
        },
        mouseleave: e => {
          record.iconVisible = false
        },
      },
    }
  }

  private async closeDrawer(needSave): Promise<any> {
    if (needSave) {
      const result = await this.saveAssessment()
      if (result !== 'success') return
    }
    this.currentAssessment = {}
    this.drawerVisible = false
  }

  private saveAssessment(): Promise<string> {
    if (!this.drawerVisible) return new Promise(resolve => resolve(''))
    return new Promise(resolve => {
      this.updateLoading = true
      const { studentId, effort, attainment, examPercentage, comment } = this.currentAssessment
      const courseId = this.filter.courseId[1]
      const request = {
        courseId,
        gradePeriodId: this.filter.monthlyGradePeriodId,
        studentId,
        effort: effort || undefined,
        attainment: attainment || undefined,
        examPercentage: examPercentage || undefined,
        comment,
      } as any
      MonthlyGradeController.updateStudentGrade(request)
        .then(res => {
          this.$message.success(this.$tc('common.saveSuccess'))
          this.getData()
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.updateLoading = false
          resolve('success')
        })
    })
  }

  private async nextStudent(index): Promise<any> {
    const result = await this.saveAssessment()
    if (result !== 'success') return

    while (!this.data[index]) {
      index++
    }
    if (index === this.data.length) return
    const record = this.data[index]
    this.setDetail(record, index, false)
  }

  private async lastStudent(index): Promise<any> {
    const result = await this.saveAssessment()
    if (result !== 'success') return
    while (!this.data[index]) {
      index--
    }
    if (index === -1) return
    const record = this.data[index]
    this.setDetail(record, index, false)
  }

  private async setDetail(record, index, needSave): Promise<any> {
    // this.type = type
    if (needSave) {
      const result = await this.saveAssessment()
      if (result !== 'success') return
    }
    this.nextVisible = !this.validateIsLast(index)
    this.lastVisible = !this.validateIsFirst(index)
    this.studentMenuKey = [record.studentId]

    this.drawerVisible = true
    this.currentAssessment = cloneDeep({
      ...record,
      effort: record.effort?.key,
      attainment: record.attainment?.key,
      comment: record.comments,
      index: index,
    })
  }

  private validateIsFirst(index): boolean {
    do {
      index--
      if (index === -1) {
        return true
      }
    } while (!this.data[index])
    return false
  }

  private validateIsLast(index): boolean {
    do {
      index++
      if (index === this.data.length) {
        return true
      }
    } while (!this.data[index])
    return false
  }

  private changeMenu({ item, key, keyPath }): void {
    this.studentMenuKey = [key]
    let idx = this.data.findIndex(item => item.studentId === key)
    this.setDetail(this.data[idx], idx, true)
  }

  private changeGradePeriod(value): void {
    const option = this.monthlyGradePeriods.filter(item => item.key === value)[0]
    this.gradePeriodSetting = option.extraValue
    this.getData()
  }
}
