



















import { Component, Vue, Watch } from 'vue-property-decorator'
import ReportViewer from './components/ReportViewer.vue'

@Component({
  components: {
    ReportViewer,
  },
})
export default class courseMaterial extends Vue {
  private get isChildRoute(): boolean {
    return this.$route.name !== 'report'
  }

  private get type(): any {
    return this.$route.params.reportType || 'monthly'
  }

  @Watch('$route', { immediate: true })
  private onRouteChange(to): void {
    if (to.name === 'report2' && !to.params.reportType) {
      this.$router.replace({ name: 'report2', params: { reportType: 'monthly' } }).catch(err => {})
    }
  }

  private changeType({ item, key, keyPath }): void {
    this.$router.push({ name: 'report2', params: { reportType: key } }).catch(err => {})
  }
}
