













































































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import FilterGroup from '@/components/filterGroup'
import { clearEmptyArray } from '@/utils/utils'
import cloneDeep from 'lodash/cloneDeep'
import { debounce, round } from 'lodash'
import {
  DropDownController,
  GradeController,
  MonthlyGradeController,
} from '@/services/request.service'
import FlexTooltip from '@/components/FlexTooltip.vue'
import { filterData } from '@/utils/utils'

@Component({
  components: {
    FilterGroup,
    FlexTooltip,
  },
})
export default class CommentViewer extends Vue {
  @Prop() private readonly viewerType: any

  private filter = {
    schoolYearId: undefined,
    gradePeriodId: [],
    optionId: undefined,
    classId: [],
    studentName: undefined,
    studentNum: undefined,
    houseGroupId: undefined,
  }
  private loading = false
  private data: Array<any> = []
  private schoolYears: Array<any> = []
  private studentMenuKey: any = []
  private gradePeriods: Array<any> = []
  private teachers: Array<any> = []
  private periodStatus = ''
  private drawerVisible = false
  private drawerInfo: any = {}
  private updateLoading = false
  private options: any = []
  private classes: any = []
  private houses: any = []
  private type2Code = {
    headTeacher: 'homeroom',
    advisor: 'advisory',
    deputyHead: 'deputyHead',
  }
  private nextVisible: boolean = true
  private lastVisible: boolean = true
  private editStudent: any = {}
  private editTeacher: any = {}
  private reqId = 0
  private round = round
  private filterData = filterData

  private canEditAll: boolean = false
  private campusType: any = ''

  private get locale(): string {
    return this.$store.state.locale
  }

  private get columns(): Array<any> {
    return [
      {
        key: 'studentName',
        title: this.$t('common.name'),
        ellipsis: true,
        scopedSlots: {
          customRender: 'name',
        },
        width: 220,
        fixed: 'left',
      },
      {
        key: 'code',
        title: this.$t('common.studentId'),
        width: 100,
        dataIndex: 'studentNum',
        fixed: 'left',
      },
      {
        key: 'gender',
        title: this.$t('personal.sex'),
        width: 80,
        scopedSlots: {
          customRender: 'gender',
        },
        fixed: 'left',
      },
      ...this.teachers.map(teacher => {
        return {
          key: `${teacher.type}_${teacher.key}`,
          title: teacher.value,
          scopedSlots: {
            customRender: `${teacher.type}_${teacher.key}`,
          },
          ellipsis: true,
          customCell: (record, rowIndex) => {
            return {
              on: {
                click: () => {
                  // if (this.periodStatus === 'processing' && this.operationAuths.includes('2107')) {
                  if (
                    (this.viewerType === 'deputyHead'
                      ? this.operationAuths.includes('2123')
                      : this.operationAuths.includes('2107')) &&
                    (this.memberId === teacher.key || this.canEditAll) &&
                    this.periodStatus !== 'lock'
                  ) {
                    this.setDetail(record, teacher, rowIndex, false)
                  }
                },
              },
            }
          },
        }
      }),
    ].filter(item => item)
  }

  private get operationAuths(): any {
    return this.$store.state.operationAuths
  }

  private get memberId(): number {
    return this.$store.state.memberId
  }

  private created(): void {
    this.getSchoolYears()
  }

  private getSchoolYears(): void {
    DropDownController.getSchoolYearRuleList()
      .then(res => {
        this.schoolYears = res.data
        if (res.data[0]) {
          this.$set(this.filter, 'schoolYearId', res.data[0].key)
          this.getGradePeriods(res.data[0].key)
        }
      })
      .catch(err => {
        console.error(err)
      })
  }

  private getGradePeriods(schoolYearId): void {
    this.gradePeriods = []
    this.filter.gradePeriodId = []
    MonthlyGradeController.getGradePeriods(schoolYearId, this.type2Code[this.viewerType])
      .then(res => {
        this.gradePeriods = clearEmptyArray(res.data.sort((a, b) => a.extraValue - b.extraValue))
        if (res.data[0]) {
          this.$set(this.filter, 'gradePeriodId', [res.data[0].key, res.data[0].subOptions[0].key])
          this.periodStatus = res.data[0].subOptions[0].extraValue.status
          this.campusType = res.data[0].extraValue
          this.getCourseClasses(res.data[0].subOptions[0].key)
        }
      })
      .catch(err => {
        console.error(err)
      })
  }

  private getCourseClasses(gradePeriodId): void {
    this.options = []
    this.data = []
    this.teachers = []
    this.$set(this.filter, 'classId', [])
    this.$set(this.filter, 'houseGroupId', undefined)

    Promise.all([
      DropDownController.getClassByCampus(this.campusType),
      DropDownController.getHouseGroupList(gradePeriodId),
    ])
      .then(res => {
        this.classes = clearEmptyArray(res[0].data)
        this.houses = res[1].data
        if (this.viewerType === 'advisor') {
          if (this.houses[0] && this.houses[0].houseGroups[0]) {
            this.$set(this.filter, 'houseGroupId', this.houses[0].houseGroups[0].houseGroupId)
          } else if (this.classes[0] && this.classes[0].subOptions[0]) {
            this.$set(this.filter, 'classId', [
              this.classes[0].key,
              this.classes[0].subOptions[0].key,
            ])
          }
        }

        this.getData()
      })
      .catch(err => {
        console.log(err)
      })
  }

  private changeSchoolYear(schoolYearId): void {
    this.data = []
    this.teachers = []
    this.getGradePeriods(schoolYearId)
  }

  private changeGradePeriod(value): void {
    this.gradePeriods.forEach(item => {
      if (item.key === value[0]) {
        this.campusType = item.extraValue
      }
      item.subOptions.forEach(it => {
        if (it.key === value[1]) {
          this.periodStatus = it.extraValue.status
        }
      })
    })
    this.getCourseClasses(value[1])
  }

  private getData(): void {
    this.loading = true
    this.data = []
    this.teachers = []
    if (!this.filter.gradePeriodId[1]) return

    const { gradePeriodId, classId, houseGroupId, studentName, studentNum } = this.filter

    if (this.viewerType !== 'deputyHead') {
      if (!classId[1] && !houseGroupId) {
        this.loading = false
        return
      }

      MonthlyGradeController.getStudentComments(
        gradePeriodId[1],
        'advisory',
        classId[1],
        houseGroupId
      )
        .then(res => {
          this.teachers = res.data.teachers

          // 结构改造
          const typeList = Array.from(new Set(this.teachers.map(item => item.type)))
          let commentByType = {}
          typeList.forEach(item => {
            commentByType[item] = {}
          })

          this.data = res.data.items.map(item => ({
            ...item,
            teacherComments: Object.assign(cloneDeep(commentByType), item.teacherComments),
          }))

          this.canEditAll = res.data.canEditAll
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.loading = false
        })
    } else {
      MonthlyGradeController.getDeputyHeadComments(
        gradePeriodId[1],
        classId[1],
        houseGroupId,
        studentName,
        studentNum
      )
        .then(res => {
          this.teachers = res.data.teachers

          // 结构改造
          const typeList = Array.from(new Set(this.teachers.map(item => item.type)))
          let commentByType = {}
          typeList.forEach(item => {
            commentByType[item] = {}
          })

          this.data = res.data.items.map(item => ({
            ...item,
            teacherComments: Object.assign(cloneDeep(commentByType), item.teacherComments),
          }))

          this.canEditAll = res.data.canEditAll
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.loading = false
        })
    }
  }

  private fetchData = debounce(() => {
    this.getData()
  }, 500)

  private async closeDrawer(needSave): Promise<any> {
    if (needSave) {
      const result = await this.saveComment()
      if (result !== 'success') return
    }
    this.drawerInfo = {}
    this.drawerVisible = false
    this.editStudent = undefined
    this.editTeacher = undefined
  }

  private changeMenu({ item, key, keyPath }): void {
    this.studentMenuKey = [key]
    let idx = this.data.findIndex(item => item.studentId === key)
    this.setDetail(this.data[idx], this.editTeacher, idx, true)
  }

  private async setDetail(student, teacher, index, needSave): Promise<any> {
    if (needSave) {
      const result = await this.saveComment()
      if (result !== 'success') return
    }
    this.nextVisible = !this.validateIsLast(index)
    this.lastVisible = !this.validateIsFirst(index)
    this.editStudent = student
    this.editTeacher = teacher
    this.studentMenuKey = [student.studentId]

    this.drawerVisible = true
    this.drawerInfo = {
      ...student,
      comment: student.teacherComments[teacher.type]
        ? student.teacherComments[teacher.type][teacher.key]
        : '',
      teacherName: teacher.value,
      teacherId: teacher.key,
      index: index,
    }
  }

  private saveComment(): any {
    if (!this.drawerVisible) return
    return new Promise(resolve => {
      this.updateLoading = true
      // const optionId = ['advisor', 'lifeBlock'].includes(this.viewerType) ? this.filter.optionId : this.filter.optionId?.[1]
      // const type =
      //   this.viewerType === 'advisor'
      //     ? 'advisory'
      //     : this.viewerType === 'headTeacher'
      //     ? 'homeroom'
      //     : 'deputyHead'
      const request = {
        studentId: this.drawerInfo.studentId,
        comment: this.drawerInfo.comment || '',
        gradePeriodId: this.filter.gradePeriodId[1],
        type: this.editTeacher.type,
        teacherId: this.editTeacher.key,
      } as any
      const student = this.editStudent
      const teacher = this.editTeacher
      MonthlyGradeController.updateComment(request)
        .then(res => {
          this.$message.success(this.$tc('common.saveSuccess'))
          this.$set(student.teacherComments[teacher.type], teacher.key, request.comment)
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.updateLoading = false
          resolve('success')
        })
    })
  }

  private async nextStudent(index): Promise<any> {
    const result = await this.saveComment()
    if (result !== 'success') return

    while (!this.data[index]) {
      index++
    }
    if (index === this.data.length) return
    const student = this.data[index]

    this.setDetail(student, this.editTeacher, index, false)
  }

  private async lastStudent(index): Promise<any> {
    const result = await this.saveComment()
    if (result !== 'success') return
    while (!this.data[index]) {
      index--
    }
    if (index === -1) return
    const student = this.data[index]

    this.setDetail(student, this.editTeacher, index, false)
  }

  private validateIsFirst(index): boolean {
    do {
      index--
      if (index === -1) {
        return true
      }
    } while (!this.data[index])
    return false
  }

  private validateIsLast(index): boolean {
    do {
      index++
      if (index === this.data.length) {
        return true
      }
    } while (!this.data[index])
    return false
  }

  @Watch('viewerType')
  private onTypeChange(newVal): void {
    this.data = []
    this.teachers = []
    this.filter.optionId = undefined
    this.getGradePeriods(this.filter.schoolYearId)
  }
}
