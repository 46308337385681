





















































import { MonthlyGradeController } from '@/services/request.service'
import { Vue, Component, Prop, Emit, Model, Watch } from 'vue-property-decorator'
import FlexTooltip from '@/components/FlexTooltip.vue'
import { exportXlsx, writeFile } from '@/utils/xlsx'
import Excel from 'exceljs'
@Component({
  components: {
    FlexTooltip,
  },
})
export default class CommentProgress extends Vue {
  @Model('change', { type: Boolean }) private readonly visible!: boolean
  @Prop() private readonly data!: any

  private loading: any = false
  private progressData: any = {
    list: [],
    all: 0,
    graded: 0,
  }
  private type2Code: any = {
    tutor: 'advisory',
    headerTeacher: 'homeroom',
    deputyHead: 'deputyHead',
    courseTeacher: 'course',
  }

  private get columns(): any {
    return this.data.type !== 'assess'
      ? this.data.type === 'percentage'
        ? [
            {
              dataIndex: 'course',
              title: this.$t(`monthlyMgm.singleColumn.percentage`),
              ellipsis: true,
              scopedSlots: { customRender: 'commonFlex' },
            },
            {
              dataIndex: 'percentage',
              title: 'Percentage',
              ellipsis: true,
              scopedSlots: { customRender: 'commonFlex' },
            },
          ]
        : [
            {
              dataIndex: 'item',
              title: ['headerTeacher', 'tutor'].includes(this.type)
                ? this.progressData.realTutor
                  ? this.$t(`monthlyMgm.singleColumn.tutor`)
                  : this.$t(`monthlyMgm.singleColumn.headerTeacher`)
                : this.type
                ? this.$t(`monthlyMgm.singleColumn.${this.type}`)
                : '',
              ellipsis: true,
              scopedSlots: { customRender: 'commonFlex' },
            },
            {
              dataIndex: 'teachers',
              title: this.$t('common.teacher'),
              ellipsis: true,
              scopedSlots: { customRender: 'commonFlex' },
            },
            {
              dataIndex: 'done',
              title: this.$tc('grading.report.completeStatus'),
              scopedSlots: { customRender: 'status' },
            },
          ]
      : [
          {
            dataIndex: 'course',
            title: this.$t(`common.subjectClass`),
            ellipsis: true,
            scopedSlots: { customRender: 'commonFlex' },
          },
          {
            dataIndex: 'effort',
            title: 'Effort',
            ellipsis: true,
            scopedSlots: { customRender: 'commonFlex' },
          },
          {
            dataIndex: 'attainment',
            title: 'Attainment',
            scopedSlots: { customRender: 'commonFlex' },
          },
        ]
  }

  private get type(): any {
    return this.data.type
  }

  private get typeCode(): any {
    const typeCode = {
      homeroom: this.$t('router.headTeacherRemark'),
      subjectClass: this.$t('router.subjectRemark'),
      advisory: this.$t('router.advisorRemark'),
      lifeBlock: this.$t('router.LBRemark'),
      assess: this.$t('eGrade.gradeAssessment'),
    }
    return typeCode[this.type]
  }

  private get locale(): any {
    return this.$store.state.locale
  }

  private flattenData(list): any {
    return list.reduce((total, current) => {
      if (current.childItems && current.childItems.length) {
        let all = current.childItems.map((child, index) => ({
          skill: current.description,
          skillValue: child.description,
          abbr: child.abbreviation,
          rowSpan: index === 0 ? current.childItems.length : 0,
        }))
        total = total.concat(all)
      }
      return total
    }, [])
  }

  private getProgressData(): any {
    this.loading = true
    if (['assess', 'percentage'].includes(this.type)) {
      MonthlyGradeController.getMonthlyGradeProgress(this.data.gradePeriodId, this.data.studentId)
        .then(res => {
          this.progressData = {
            all: res.data.all,
            graded: res.data.graded,
            scored: res.data.scored,
            list: res.data.items,
          }
        })
        .finally(() => (this.loading = false))
    } else {
      MonthlyGradeController.getCommentProgress(
        this.type2Code[this.type],
        this.data.gradePeriodId,
        this.data.studentId
      )
        .then(res => {
          this.progressData = {
            all: this.data.type === 'deputyHead' ? 1 : res.data.all,
            graded: res.data.completed,
            list: res.data.items,
            realTutor: res.data.realTutor,
          }
        })
        .finally(() => (this.loading = false))
    }
  }

  @Watch('visible', { immediate: true })
  private onVisChange(val) {
    if (val) {
      this.getProgressData()
    }
  }

  @Emit('change')
  private closeModal(): boolean {
    return false
  }
}
