









































































































































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import TableTotal from '@/components/TableTotal.vue'
import moment from 'moment'
import FlexTooltip from '@/components/FlexTooltip.vue'
import filterGroup from '@/components/filterGroup'
import {
  GradeController,
  DropDownController,
  MonthlyGradeController,
} from '@/services/request.service'
import {
  clearEmptyArray,
  toPage,
  handleBatchDownload,
  getToken,
  getSchoolInfo,
} from '@/utils/utils'
import PreviewModal from '@/components/Report/PreviewModal.vue'
import saveAs from '@/utils/FileSaver'
import debounce from 'lodash/debounce'
import ConfirmModal from './ConfirmModal.vue'
import { exportXlsx, writeFile } from '@/utils/xlsx'
import EventBus from '@/utils/EventBus'
import CommentProgress from './CommentProgress.vue'
import { createPagination, waitFor } from '@/constant/constant'
import cloneDeep from 'lodash/cloneDeep'
import OperationsGroup from '@/components/OperationsGroup'
Component.registerHooks(['beforeRouteLeave'])

@Component({
  components: {
    FlexTooltip,
    filterGroup,
    PreviewModal,
    TableTotal,
    ConfirmModal,
    CommentProgress,
    OperationsGroup,
  },
})
export default class ReportViewer extends Vue {
  @Prop() private readonly type!: string

  private advisories: any = []
  private data: any = []
  private filters: any = {
    homeroomId: [],
    schoolYearId: undefined,
    timeId: undefined,
    periodId: undefined,
    name: '',
  }
  private campusCode: any = {
    1231: 'elementary',
    1232: 'middle',
    1233: 'high',
  }
  private homerooms: any = []
  private schoolYearList: any = []
  private periods: any = []
  private downloading: boolean = false
  private generating: boolean = false
  private loading: boolean = false
  private pagination: any = createPagination({})
  private previewVisible: boolean = false
  private progressData: any = {}
  private progressVis: any = false
  private reportData: any = {}
  private selectedRows: any = []
  // private selectedRowKeys: any = []
  private moment = moment
  private periodStatus = ''
  private tipsVisible = true
  private campusType = ''
  private previewId = -1
  private commentsCtrl = {
    courseTeacher: false,
    headteacher: false,
    tutor: false,
    deputyHead: false,
    ep: false,
    ea: false,
  }
  private exportLoading: any = false

  private reqId = 0

  // 全选学生本地保存
  private allStudentsIds: any = []

  // 生成报告loading
  private generateReporting: Boolean = false
  // 生成报告进度
  private schedule: any = 0
  // 生成报告完成flag
  private result: boolean = false
  // 是否生成过报告
  private periodReport: boolean = false
  // 轮询定时器
  private timer: any = null

  private sending: boolean = false
  private sendResult: any = []
  private resultVis: any = false

  private get columns(): Array<any> {
    return [
      {
        dataIndex: 'studentName',
        key: 'name',
        fixed: 'left',
        width: 300 * this.ratio,
        title: this.$t('common.studentName'),
        ellipsis: true,
        scopedSlots: { customRender: 'commonBlock' },
      },
      {
        dataIndex: 'studentNum',
        ellipsis: true,
        width: 150 * this.ratio,
        title: this.$t('common.studentId'),
        scopedSlots: { customRender: 'commonBlock' },
      },

      ['1230', '1231'].includes(this.campusType)
        ? {
            dataIndex: 'className',
            width: 150 * this.ratio,
            title: this.$t('common.homeroom'),
          }
        : {
            dataIndex: 'houseGroup',
            width: 150 * this.ratio,
            title: this.$t('common.advisory'),
          },

      this.commentsCtrl.ea && {
        key: 'progress',
        title: this.$t('monthlyMgm.progress'),
        width: 200 * this.ratio,
        scopedSlots: { customRender: 'progress' },
        filterDropdown: true, // 自定义的列筛选功能，我们占位为信息提示Icon的位置
        filterIcon: () => {
          return this.$createElement(
            'a-tooltip',
            {
              props: {
                title: this.$t('monthlyMgm.progressTip'),
                getPopupContainer: () => this.$document.body,
              },
            },
            [
              this.$createElement('a-icon', {
                props: {
                  type: 'question-circle',
                },
              }),
            ]
          )
        },
      },
      this.commentsCtrl.courseTeacher && {
        key: 'courseTeacherdRemark',
        title: this.$t('monthlyMgm.courseTeacher'),
        width: 200 * this.ratio,
        scopedSlots: { customRender: 'courseTeacherRemark' },
        filterDropdown: true, // 自定义的列筛选功能，我们占位为信息提示Icon的位置
        filterIcon: () => {
          return this.$createElement(
            'a-tooltip',
            {
              props: {
                title: this.$t('eGrade.commentTips'),
                getPopupContainer: () => this.$document.body,
              },
            },
            [
              this.$createElement('a-icon', {
                props: {
                  type: 'question-circle',
                },
              }),
            ]
          )
        },
      },
      this.commentsCtrl.ep && {
        key: 'percentageRemark',
        title: this.$t('monthlyMgm.percentage'),
        width: 200 * this.ratio,
        scopedSlots: { customRender: 'percentage' },
        filterDropdown: true, // 自定义的列筛选功能，我们占位为信息提示Icon的位置
        filterIcon: () => {
          return this.$createElement(
            'a-tooltip',
            {
              props: {
                title: this.$t('monthlyMgm.progressTip'),
                getPopupContainer: () => this.$document.body,
              },
            },
            [
              this.$createElement('a-icon', {
                props: {
                  type: 'question-circle',
                },
              }),
            ]
          )
        },
      },
      // this.commentsCtrl.headteacher && {
      //   key: 'headteacherRemark',
      //   title: this.$tc('eGrade.headteacherRemark'),
      //   width: 200 * this.ratio,
      //   scopedSlots: { customRender: 'headteacherRemark' },
      //   filterDropdown: true, // 自定义的列筛选功能，我们占位为信息提示Icon的位置
      //   filterIcon: () => {
      //     return this.$createElement(
      //       'a-tooltip',
      //       {
      //         props: {
      //           title: this.$tc('eGrade.commentTips'),
      //           getPopupContainer: () => this.$document.body,
      //         },
      //       },
      //       [
      //         this.$createElement('a-icon', {
      //           props: {
      //             type: 'question-circle',
      //           },
      //         }),
      //       ]
      //     )
      //   },
      // },
      this.commentsCtrl.tutor && {
        key: 'advisorRemark',
        title: this.$t('monthlyMgm.advisorRemark'),
        width: 200 * this.ratio,
        scopedSlots: { customRender: 'advisorRemark' },
        filterDropdown: true, // 自定义的列筛选功能，我们占位为信息提示Icon的位置
        filterIcon: () => {
          return this.$createElement(
            'a-tooltip',
            {
              props: {
                title: this.$t('eGrade.commentTips'),
                getPopupContainer: () => this.$document.body,
              },
            },
            [
              this.$createElement('a-icon', {
                props: {
                  type: 'question-circle',
                },
              }),
            ]
          )
        },
      },
      this.commentsCtrl.deputyHead && {
        key: 'deputyHeadRemark',
        title: this.$t('monthlyMgm.deputyHead'),
        width: 200 * this.ratio,
        scopedSlots: { customRender: 'deputyHeadRemark' },
        filterDropdown: true, // 自定义的列筛选功能，我们占位为信息提示Icon的位置
        filterIcon: () => {
          return this.$createElement(
            'a-tooltip',
            {
              props: {
                title: this.$t('eGrade.commentTips'),
                getPopupContainer: () => this.$document.body,
              },
            },
            [
              this.$createElement('a-icon', {
                props: {
                  type: 'question-circle',
                },
              }),
            ]
          )
        },
      },
      // 权限和报告完成
      this.operationAuths.includes('2109') && {
        // this.result && { // this.periodReport &&
        key: 'operations',
        width: 220,
        fixed: 'right',
        title: this.$t('common.operations'),
        scopedSlots: { customRender: 'operations' },
      },
    ].filter(item => item)
  }

  private get excelColumns(): Array<any> {
    return [
      {
        dataIndex: 'lastName',
        title: this.$t('common.surname'),
        width: 10,
      },
      {
        dataIndex: 'enName',
        title: this.$t('common.enName'),
        width: 10,
      },
      {
        dataIndex: 'firstName',
        title: this.$t('common.givenName'),
        width: 10,
      },
      {
        dataIndex: 'name',
        title: this.$t('common.cnName'),
        width: 10,
      },
      {
        dataIndex: 'studentNum',
        title: this.$t('common.studentId'),
        width: 15,
      },
      {
        dataIndex: 'grade',
        title: this.$t('common.grade'),
        width: 15,
      },
      {
        dataIndex: 'houseGroup',
        title: this.$t('common.homeroom'),
        width: 15,
      },
      {
        dataIndex: 'headTeacher',
        title: this.$t('common.headTeacher'),
        width: 20,
      },
      {
        dataIndex: 'formTime',
        title: this.$tc('common.advisory'),
        width: 15,
      },
      {
        dataIndex: 'tutor',
        title: this.$t('common.tutor'),
        width: 20,
      },
      {
        dataIndex: 'comments',
        title: this.$t('monthlyMgm.comments'),
        width: 30,
      },
      // {
      //   dataIndex: 'course',
      //   title: this.$t('common.subject'),
      //   width: 20,
      // },
      // {
      //   dataIndex: 'teachers',
      //   title: this.$t('common.teacher'),
      //   width: 20,
      // },
      // {
      //   dataIndex: 'effort',
      //   title: 'Effort',
      //   width: 15,
      // },
      // {
      //   dataIndex: 'attainment',
      //   title: 'Attainment',
      //   width: 15,
      // },
      // {
      //   dataIndex: 'housePoint',
      //   title: 'HousePOint',
      //   width: 15,
      // },
      // {
      //   dataIndex: 'houseAchievement',
      //   title: 'HouseAchievement',
      //   width: 20,
      // },
      // {
      //   dataIndex: 'conductPoint',
      //   title: 'ConductPoint',
      //   width: 15,
      // },
      // {
      //   dataIndex: 'behaviourEvent',
      //   title: 'BehaviourEvent',
      //   width: 20,
      // },
    ].filter(item => item)
  }
  private get locale(): any {
    return this.$store.state.locale
  }
  private get operationAuths(): any {
    return this.$store.state.operationAuths
  }
  private get schoolYearId(): any {
    return this.$store.state.currentSchoolYear.schoolYearId
  }
  private get selectedRowKeys(): any {
    return this.selectedRows.map(item => item.studentId)
  }
  private get ratio(): any {
    return this.$store.state.ratio
  }
  private get userAuths(): any {
    return this.$store.state.userAuths
  }
  private get periodId(): any {
    return this.filters.periodId?.[1]
  }
  private get reportDate(): any {
    let campus = this.periods.find(item => item.key === this.filters.periodId[0])
    let period = campus.subOptions.find(item => item.key === this.filters.periodId[1])
    return period.value
  }

  private get reportType(): any {
    return this.$route.params.reportType
  }

  private calcDis(record): any {
    return record.sent === 'sent'
  }

  private clearSelection(): void {
    this.selectedRows = []
  }

  private selectAll(): void {
    this.clearSelection()
    this.selectedRows = cloneDeep(this.allStudentsIds)
  }

  private changeYear(): void {
    this.$nextTick(() => {
      this.clearSelection()
      this.data = []
      this.getPeriodDropDown()
    })
  }

  private created(): void {
    this.getDropDownInfo()
  }

  private sendReport(record) {
    this.$set(record, 'sending', true)
    MonthlyGradeController.sendBatch([record.studentId], this.periodId, 'sent')
      .then(res => {
        if (!res.data?.length) {
          this.$message.success(this.$tc('tips.sendSuccess'))
          return
        }
        this.resultVis = true
        this.sendResult = res.data.map(item => {
          return {
            name: item.student,
            reason: this.$t(`errorCode.${item.status}`),
          }
        })
      })
      .catch(err => console.log(err))
      .finally(() => {
        this.$set(record, 'sending', false)
        this.refresh()
      })
  }

  private withdrawReport(record) {
    this.$set(record, 'withdrawing', true)
    MonthlyGradeController.sendBatch([record.studentId], this.periodId, 'withdraw')
      .then(res => {
        if (!res.data?.length) {
          this.$message.success(this.$tc('message.recallSuccess'))
          return
        }
      })
      .catch(err => console.log(err))
      .finally(() => {
        this.$set(record, 'withdrawing', false)
        this.refresh()
      })
  }

  private batchSend(): void {
    if (this.selectedRows.length === 0) {
      this.$message.error(this.$tc('tips.selectStudents'))
      return
    }
    let arr = [] as any
    this.selectedRows.forEach(item => {
      if (item.status === 'sent') return
      arr.push(item.studentId)
    })
    if (!arr.length) {
      this.$message.error(this.$tc('tips.selectAssessStudent'))
      return
    }
    this.$confirm({
      title: this.$t('grading.report.sendConfirm'),
      onOk: () => {
        this.sending = true
        MonthlyGradeController.sendBatch(arr, this.periodId, 'sent')
          .then(res => {
            if (!res.data?.length) {
              this.$message.success(this.$tc('tips.sendSuccess'))
              return
            }
            this.resultVis = true
            this.sendResult = res.data.map(item => {
              return {
                name: item.student,
                reason: this.$t(`errorCode.${item.status}`),
              }
            })
          })
          .catch(err => console.log(err))
          .finally(() => {
            this.sending = false
            this.refresh()
          })
      },
    })
  }

  private downloadReport(record) {
    this.$set(record, 'downloading', true)
    const domain = process.env.VUE_APP_DOMAIN
    const printUrl = process.env.VUE_APP_PDF_PRINT_URL
    let token = getToken()
    let schoolInfo = getSchoolInfo()
    let targetUrl = `${domain}exportMonthlyReport/${token}/${schoolInfo.schoolId}/${record.studentId}/${this.periodId}`
    let url = `${printUrl}api/render/?url=${targetUrl}&pdf.printBackground=true&pdf.margin.left=3.5mm&pdf.margin.right=4mm&pdf.margin.top=28mm&waitFor=${'.loaded'}`
    // window.open(targetUrl)

    const reportName = this.reportDate + '报告单'
    const reportEnName = 'Report for ' + this.reportDate + '.pdf'
    const name = record.studentName + '_' + reportName + '_' + reportEnName
    saveAs(url, `${name}`, record.studentId)
    EventBus.$once(`onload_${record.studentId}`, () => {
      this.$set(record, 'downloading', false)
    })
  }

  private batchDownload(): void {
    if (this.selectedRows.length === 0) {
      this.$message.error(this.$tc('tips.selectStudents'))
      return
    }
    this.downloading = true

    let campus: any = {}
    campus = this.periods.find(item => item.key === this.filters.periodId[0])

    const reportName = this.reportDate + '报告单'
    const reportEnName = 'Report for ' + this.reportDate + '.pdf'
    const name = `${this.locale === 'zh' ? campus.value : campus.enValue}_${
      this.reportDate
    }_Report.zip`

    const domain = process.env.VUE_APP_DOMAIN
    const printUrl = process.env.VUE_APP_PDF_PRINT_URL
    const token = getToken()
    const schoolInfo = getSchoolInfo()
    let arr = [] as any
    this.selectedRows.forEach(item => {
      let targetUrl = `${domain}exportMonthlyReport/${token}/${schoolInfo.schoolId}/${item.studentId}/${this.periodId}`
      let url = `${printUrl}api/render/?url=${targetUrl}&pdf.printBackground=true&pdf.margin.left=3.5mm&pdf.margin.right=4mm&pdf.margin.top=28mm&waitFor=${'.loaded'}`
      arr.push({
        url,
        name: item.studentName + '_' + reportName + '_' + reportEnName + '.pdf',
      })
    })
    // if(!arr.length){
    //   this.$message.error(this.$tc('tips.selectStudents'))
    //   this.unavailableStudent = true
    //   return
    // }
    handleBatchDownload(arr, name, () => {
      this.downloading = false
    })
  }

  private async exportComments(): Promise<void> {
    this.exportLoading = true
    MonthlyGradeController.getMonthlyCommentDetails(this.periodId)
      .then(async res => {
        const students = res.data
        const file = exportXlsx(this.excelColumns, students)

        const ws = file.getWorksheet(1)

        ws.eachRow(row => {
          row.height = 30
          row.eachCell(cell => {
            cell.border = {
              top: { style: 'thin', color: { argb: 'FF000000' } },
              left: { style: 'thin', color: { argb: 'FF000000' } },
              bottom: { style: 'thin', color: { argb: 'FF000000' } },
              right: { style: 'thin', color: { argb: 'FF000000' } },
            }
            cell.style.alignment = { vertical: 'middle', horizontal: 'center' }
          })
        })
        const current = moment().format('YYYY.MM.DD')
        await writeFile(file, `Comment Report_${current}.xlsx`)
        this.exportLoading = false
      })
      .catch(err => console.log(err))
      .finally(() => (this.exportLoading = false))
  }

  private filterData(inputValue, path) {
    return path.some(option => {
      return option.value?.toLowerCase().includes(inputValue?.toLowerCase())
    })
  }

  private fetchData = debounce(() => {
    this.getData()
  }, 500)

  private getData(page = { current: 1, pageSize: this.pagination.defaultPageSize }): void {
    if (!this.periodId) return
    this.loading = true
    this.data = []
    let reqId = ++this.reqId
    this.pagination.current = page.current

    const gradePeriodId = this.periodId
    const classId = this.filters.homeroomId[1]
    const houseId = this.filters.advisoryId
    const studentName = this.filters.name?.trim()

    let condition = {
      gradePeriodId,
      classId,
      houseId,
      studentName,
      pageCurrent: page.current,
      pageSize: page.pageSize,
    }

    this.getStudentIdList(gradePeriodId, classId, houseId, studentName)
    // this.selectedRows = []

    MonthlyGradeController.getMonthlyReports(condition)
      .then(res => {
        if (reqId !== this.reqId) return
        this.data = res.data.items
        this.pagination.total = res.data.totalItem
        this.pagination.current = page.current
        toPage(page, this.data, page => {
          this.pagination.current = page
          this.refresh()
        })
      })
      .catch(err => console.log(err))
      .finally(() => {
        if (reqId !== this.reqId) return
        this.loading = false
      })
  }

  private getStudentIdList(gradePeriodId, classId, houseId, studentName): void {
    MonthlyGradeController.getMonthlyReportStudentIds({
      gradePeriodId,
      classId,
      houseId,
      studentName,
    })
      .then(res => {
        this.allStudentsIds = res.data
      })
      .catch(err => console.error(err))
  }

  private getDropDownInfo(): void {
    Promise.all([
      // DropDownController.getClassList(),
      DropDownController.getHouseGroupList(),
      DropDownController.getSchoolYearRuleList(),
    ])
      .then(res => {
        // this.homerooms = clearEmptyArray(res[0].data)
        this.advisories = res[0].data
        this.schoolYearList = res[1].data
        this.filters.schoolYearId = (res[1].data[0] || {}).key
        if (this.filters.schoolYearId) {
          this.getPeriodDropDown()
        }
      })
      .catch(err => console.log(err))
  }

  private getPeriodDropDown(): void {
    this.schedule = 0
    this.result = false
    if (!this.filters.schoolYearId) return
    MonthlyGradeController.getGradePeriods(this.filters.schoolYearId, undefined, this.type).then(
      res => {
        this.homerooms = []
        this.periods = clearEmptyArray(
          res.data
            .sort((a, b) => a.extraValue - b.extraValue)
            .map(item => ({
              ...item,
              subOptions: item.subOptions.filter(period => period.extraValue.status !== 'DNS'),
            }))
            .filter(item => item.subOptions.length)
        )

        this.filters.periodId = this.periods[0]
          ? [this.periods[0]?.key, this.periods[0]?.subOptions[0]?.key]
          : []
        this.campusType = this.periods[0]?.extraValue
        this.periodStatus = this.periods[0]?.subOptions[0]?.extraValue?.status
        this.periodReport = this.periods[0]?.subOptions[0]?.extraValue?.reported
        this.commentsCtrl = {
          courseTeacher: this.periods[0]?.subOptions[0]?.extraValue?.courseTeacher,
          headteacher: this.periods[0]?.subOptions[0]?.extraValue?.headTeacher,
          tutor: this.periods[0]?.subOptions[0]?.extraValue?.tutor,
          deputyHead: this.periods[0]?.subOptions[0]?.extraValue.deputyHead,
          ep: this.periods[0]?.subOptions[0]?.extraValue.ep,
          ea: this.periods[0]?.subOptions[0]?.extraValue.ea,
        }
        this.getHomeroom()

        this.getData()
        if (this.periodReport) {
          this.generateReporting = true
          this.polling()
        } else {
          this.generateReporting = false
          clearInterval(this.timer)
        }
      }
    )
  }

  private preView(record): void {
    // this.$set(record, 'previewing', true)
    // this.previewId = record.studentId
    // MonthlyGradeController.getMonthlyReport(this.periodId, record.studentId)
    //   .then(res => {
    //     this.reportData = res.data
    //     // this.$set(this.reportData, 'reportDate', this.reportDate)
    //     this.previewVisible = true
    //   })
    //   .catch(err => console.log(err))
    //   .finally(() => {
    //     this.$set(record, 'previewing', false)
    //     this.previewId = -1
    //   })
    const domain = process.env.VUE_APP_DOMAIN
    const printUrl = process.env.VUE_APP_PDF_PRINT_URL
    let token = getToken()
    let schoolInfo = getSchoolInfo()
    let targetUrl = `${domain}exportMonthlyReport/${token}/${schoolInfo.schoolId}/${record.studentId}/${this.periodId}`
    let url = `${printUrl}api/render/?url=${targetUrl}&pdf.printBackground=true&pdf.margin.left=3.5mm&pdf.margin.right=4mm&pdf.margin.top=28mm&waitFor=${'.loaded'}`
    window.open(url)
  }

  private getHomeroom(): void {
    if (!this.campusType) return
    this.$set(this.filters, 'homeroomId', [])
    DropDownController.getClassByCampus(this.campusType)
      .then(res => {
        this.homerooms = clearEmptyArray(res.data)
      })
      .catch(err => {
        console.error(err)
      })
  }

  private handleTableChange(pagination): void {
    this.pagination.current = pagination.current
    this.pagination.pageSize = pagination.pageSize
    this.getData(pagination)
  }

  private onSelect(record, selected, selectedRows, nativeEvent): void {
    const { studentId, studentName } = record

    selected
      ? this.selectedRows.push({ studentId, studentName })
      : this.selectedRows.splice(
          this.selectedRows.findIndex(item => item.studentId === record.studentId),
          1
        )
  }

  private onSelectAll(selected, selectedRows, changeRows): void {
    if (selected) {
      changeRows.forEach(item => {
        const { studentId, studentName } = item

        this.selectedRows.push({ studentId, studentName })
      })
    } else {
      changeRows.forEach(item => {
        this.selectedRows.splice(
          this.selectedRows.findIndex(row => row.studentId === item.studentId),
          1
        )
      })
    }
  }

  private onPeriodChange(id): any {
    this.schedule = 0
    this.result = false
    this.generateReporting = false
    clearInterval(this.timer)

    this.clearSelection()
    this.tipsVisible = true
    this.periods.forEach(item => {
      if (item.key !== id[0]) return
      this.campusType = item.extraValue
      item.subOptions.forEach(it => {
        if (it.key !== id[1]) return
        // this.periodStatus = it.extraValue
        this.periodStatus = it.extraValue?.status
        this.periodReport = it.extraValue?.reported

        this.commentsCtrl = {
          courseTeacher: it.extraValue?.courseTeacher,
          headteacher: it.extraValue?.headTeacher,
          tutor: it.extraValue?.tutor,
          deputyHead: it.extraValue?.deputyHead,
          ep: it.extraValue?.ep,
          ea: it.extraValue?.ea,
        }
      })
    })
    this.getHomeroom()
    this.$nextTick(() => {
      this.getData()
      // 生成过报告 轮询进度
      if (this.periodReport) {
        this.generateReporting = true
        this.polling()
      }
    })
  }

  private refresh(): void {
    this.getData(this.pagination)
    this.selectedRows = []
  }

  private showProgressDetail(record, type): void {
    this.progressVis = true
    // let campus: any = {}
    // let period: any = {}
    //   campus = this.periods.find(item => item.key === this.filters.periodId[0])
    //   period = campus.subOptions.find(item => item.key === this.filters.periodId[1])

    this.progressData = {
      studentId: record.studentId,
      gradePeriodId: this.periodId,
      student: record.studentName,
      type,
      // period: period
    }
  }

  private generateReport(flag): void {
    if (!this.periodId) return

    MonthlyGradeController.genReport(this.periodId, flag).then(res => {
      this.schedule = Math.round(res.data.schedule * 100)
      this.result = res.data.result
      this.generateReporting = !res.data.result
      if (this.result) {
        clearInterval(this.timer)
        this.refresh()
      }
    })

    if (!flag) {
      this.generateReporting = true
      this.periodReport = true
      this.polling()

      const selectedPeriod = this.periods
        .find(item => item.key === this.filters.periodId[0])
        .subOptions.find(item => item.key === this.filters.periodId[1])

      this.$set(selectedPeriod.extraValue, 'reported', true)
    }
  }

  private polling() {
    this.generateReport(true)
    this.timer = setInterval(() => {
      setTimeout(() => {
        this.generateReport(true)
      }, 0)
    }, 5 * 1000)

    this.$once('hook:beforeDestroy', () => {
      clearInterval(this.timer)
    })
  }

  private beforeRouteLeave(to, from, next): void {
    clearInterval(this.timer)
    next()
  }

  @Watch('reportType', { immediate: true })
  public onReportTypeChange(val): void {
    clearInterval(this.timer)
  }
}
