

















































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import FilterGroup from '@/components/filterGroup'
import { clearEmptyArray } from '@/utils/utils'
import { debounce, round } from 'lodash'
import {
  DropDownController,
  GradeController,
  MonthlyGradeController,
} from '@/services/request.service'
import FlexTooltip from '@/components/FlexTooltip.vue'
import { filterData } from '@/utils/utils'
import { exportXlsx, writeFile } from '@/utils/xlsx'

@Component({
  components: {
    FilterGroup,
    FlexTooltip,
  },
})
export default class Conduct extends Vue {
  @Prop() private readonly viewerType: any

  private filter = {
    schoolYearId: undefined,
    gradePeriodId: [],
    optionId: undefined,
    courseId: [],
  }
  private loading = false
  private exportLoading: boolean = false
  private data: Array<any> = []
  private schoolYears: Array<any> = []
  private studentMenuKey: any = []
  private gradePeriods: Array<any> = []
  private teachers: Array<any> = []
  private periodStatus = ''
  private drawerVisible = false
  private drawerInfo: any = {}
  private updateLoading = false
  private options: any = []
  private courseList: any = []

  private type: string = 'house'
  private nextVisible: boolean = true
  private lastVisible: boolean = true
  private editStudent: any = {}
  // private editTeacher: any = {}
  private reqId = 0
  private round = round
  private filterData = filterData

  private campusType: any = ''

  private get locale(): string {
    return this.$store.state.locale
  }

  private get columns(): Array<any> {
    return [
      {
        key: 'studentName',
        title: this.$t('common.name'),
        ellipsis: true,
        scopedSlots: {
          customRender: 'name',
        },
        width: 220,
        fixed: 'left',
      },
      {
        key: 'code',
        title: this.$t('common.studentId'),
        width: 100,
        dataIndex: 'studentNum',
        fixed: 'left',
      },
      {
        key: 'housePoint',
        title: this.$t('monthlyMgm.housePoint'),
        width: 150,
        dataIndex: 'housePoint',
        scopedSlots: {
          customRender: 'mark',
        },
      },
      {
        key: 'houseAchievement',
        title: this.$t('monthlyMgm.houseAchievement'),
        // width: 100,
        dataIndex: 'houseAchievement',
        scopedSlots: {
          customRender: 'comment',
        },
        customCell: (record, rowIndex) => {
          return {
            on: {
              click: () => {
                if (this.periodStatus !== 'lock' && this.operationAuths.includes('2108')) {
                  // if (this.operationAuths.includes('2108')) {
                  this.type = 'house'
                  this.setDetail(record, 'house', rowIndex, false)
                }
              },
            },
          }
        },
      },
      {
        key: 'conductPoint',
        title: this.$t('monthlyMgm.conductPoint'),
        width: 150,
        dataIndex: 'conductPoint',
        scopedSlots: {
          customRender: 'mark',
        },
      },
      {
        key: 'behaviourEvent',
        title: this.$t('monthlyMgm.behaviourEvent'),
        // width: 100,
        dataIndex: 'behaviourEvent',
        scopedSlots: {
          customRender: 'comment',
        },
        customCell: (record, rowIndex) => {
          return {
            on: {
              click: () => {
                if (this.periodStatus !== 'lock' && this.operationAuths.includes('2108')) {
                  // if (this.operationAuths.includes('2108')) {
                  this.type = 'conduct'
                  this.setDetail(record, 'conduct', rowIndex, false)
                }
              },
            },
          }
        },
      },
    ].filter(item => item)
  }

  private get excelColumns(): Array<any> {
    return [
      {
        dataIndex: 'lastName',
        title: this.$t('myClass.student.lastName'),
        width: 10,
      },
      {
        dataIndex: 'enName',
        title: this.$t('common.enName'),
        width: 20,
      },
      {
        dataIndex: 'firstName',
        title: this.$t('myClass.student.firstName'),
        width: 20,
      },
      {
        dataIndex: 'name',
        title: this.$t('common.name'),
        width: 20,
      },
      {
        dataIndex: 'studentNum',
        title: this.$t('common.studentId'),
        width: 20,
      },
      {
        dataIndex: 'grade',
        title: this.$t('common.grade'),
        width: 10,
      },
      {
        dataIndex: 'advisory',
        title: this.$t('common.advisory'),
        width: 30,
      },
      {
        dataIndex: 'className',
        title: this.$t('common.homeroom'),
        width: 20,
      },
      {
        dataIndex: 'dormitory',
        title: this.$t('common.dormitory'),
        width: 20,
      },
      {
        dataIndex: 'selfStudyRoom',
        title: this.$t('common.studyRoom'),
        width: 20,
      },
      {
        dataIndex: 'housePoint',
        title: this.$t('monthlyMgm.housePoint'),
        width: 20,
      },
      {
        dataIndex: 'conductPoint',
        title: this.$t('monthlyMgm.conductPoint'),
        width: 20,
      },
      {
        dataIndex: 'schoolYearHousePoint',
        title: this.$t('monthlyMgm.schoolYearHousePoint'),
        width: 30,
      },
      {
        dataIndex: 'schoolYearConductPoint',
        title: this.$t('monthlyMgm.schoolYearConductPoint'),
        width: 30,
      },
    ]
  }

  private get operationAuths(): any {
    return this.$store.state.operationAuths
  }

  private created(): void {
    this.getSchoolYears()
    this.getHouseGroupList()
  }

  private getSchoolYears(): void {
    DropDownController.getSchoolYearRuleList()
      .then(res => {
        this.schoolYears = res.data
        if (res.data[0]) {
          this.$set(this.filter, 'schoolYearId', res.data[0].key)
          this.getGradePeriods(res.data[0].key)
        }
      })
      .catch(err => {
        console.error(err)
      })
  }

  private getGradePeriods(schoolYearId): void {
    this.gradePeriods = []
    this.filter.gradePeriodId = []
    const id = ++this.reqId
    MonthlyGradeController.getGradePeriods(schoolYearId)
      .then(res => {
        if (id !== this.reqId) return
        this.gradePeriods = clearEmptyArray(
          res.data
            .sort((a, b) => a.extraValue - b.extraValue)
            .map(item => {
              return {
                ...item,
                subOptions: item.subOptions.filter(it => it.extraValue.hc),
              }
            })
        )
        if (this.gradePeriods[0]) {
          this.$set(this.filter, 'gradePeriodId', [
            this.gradePeriods[0].key,
            this.gradePeriods[0].subOptions[0].key,
          ])
          this.campusType = this.gradePeriods[0].extraValue
          this.getCourseClasses(this.gradePeriods[0].subOptions[0].key, id)
          this.getData()
          this.periodStatus = res.data[0].subOptions[0].extraValue.status
        }
      })
      .catch(err => {
        console.error(err)
      })
  }

  private getHouseGroupList(): void {
    this.options = []

    DropDownController.getHouseGroupList()
      .then(res => {
        this.options = res.data
      })
      .catch(err => console.error(err))
  }

  private getCourseClasses(gradePeriodId, id): void {
    this.courseList = []
    this.$set(this.filter, 'courseId', [])
    DropDownController.getClassByCampus(this.campusType)
      .then(res => {
        this.courseList = clearEmptyArray(res.data)
      })
      .catch(err => console.error(err))
  }

  private changeSchoolYear(schoolYearId): void {
    this.getGradePeriods(schoolYearId)
  }

  private changeGradePeriod(value): void {
    this.gradePeriods.forEach(item => {
      if (item.key === value[0]) {
        this.campusType = item.extraValue
      }
      item.subOptions.forEach(it => {
        if (it.key === value[1]) {
          this.periodStatus = it.extraValue.status
        }
      })
    })
    this.getCourseClasses(value[1], ++this.reqId)
    this.getData()
  }

  private getData(): void {
    // const optionId = ['advisor', 'lifeBlock'].includes(this.viewerType) ? this.filter.optionId : this.filter.optionId?.[1]
    let optionId = this.filter.optionId ? this.filter.optionId : undefined
    let courseId = this.filter.courseId[1] ? this.filter.courseId[1] : undefined

    // if (!this.filter.gradePeriodId[1] || !optionId || !courseId) return
    this.loading = true
    this.data = []
    this.teachers = []
    MonthlyGradeController.getBehaviorTable(this.filter.gradePeriodId[1], optionId, courseId)
      .then(res => {
        this.data = res.data
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => {
        this.loading = false
      })
  }

  private fetchData = debounce(() => {
    this.getData()
  }, 500)

  private async closeDrawer(needSave): Promise<any> {
    if (needSave) {
      const result = await this.saveComment()
      if (result !== 'success') return
    }
    this.drawerInfo = {}
    this.drawerVisible = false
    this.editStudent = undefined
    // this.editTeacher = undefined
  }

  private changeMenu({ item, key, keyPath }): void {
    this.studentMenuKey = [key]
    let idx = this.data.findIndex(item => item.studentId === key)
    this.setDetail(this.data[idx], this.type, idx, true)
  }

  private async setDetail(student, type, index, needSave): Promise<any> {
    // this.type = type
    if (needSave) {
      const result = await this.saveComment()
      if (result !== 'success') return
    }
    this.nextVisible = !this.validateIsLast(index)
    this.lastVisible = !this.validateIsFirst(index)
    this.editStudent = student
    // this.editTeacher = teacher
    this.studentMenuKey = [student.studentId]

    this.drawerVisible = true
    this.drawerInfo = {
      ...student,

      comment: type === 'house' ? student.houseAchievement : student.behaviourEvent,
      point: type === 'house' ? student.housePoint : student.conductPoint,
      // teacherName: teacher.value,
      // teacherId: teacher.key,
      index: index,
    }
  }

  private saveComment(): any {
    if (!this.drawerVisible) return
    return new Promise(resolve => {
      this.updateLoading = true
      // const optionId = ['advisor', 'lifeBlock'].includes(this.viewerType) ? this.filter.optionId : this.filter.optionId?.[1]
      const optionId =
        this.viewerType === 'advisor' ? this.filter.optionId : this.filter.optionId?.[1]
      const type = this.type
      const request = {
        gradePeriodId: this.filter.gradePeriodId[1],
        studentId: this.drawerInfo.studentId,
        point: this.drawerInfo.point,
        comment: this.drawerInfo.comment,
        type,
      } as any
      const student = this.editStudent
      // const teacher = this.editTeacher
      MonthlyGradeController.updateBehaviorRecord(request)
        .then(res => {
          this.$message.success(this.$tc('common.saveSuccess'))
          if (type === 'house') {
            student.houseAchievement = request.comment
            student.housePoint = request.point
          } else {
            student.behaviourEvent = request.comment
            student.conductPoint = request.point
          }
        })
        .catch(err => {
          this.getData()
          console.error(err)
        })
        .finally(() => {
          this.updateLoading = false
          resolve('success')
        })
    })
  }

  private async nextStudent(index): Promise<any> {
    const result = await this.saveComment()
    if (result !== 'success') return

    while (!this.data[index]) {
      index++
    }
    if (index === this.data.length) return
    const student = this.data[index]

    // this.setDetail(student, this.editTeacher, index, false)
    this.setDetail(student, this.type, index, false)
  }

  private async lastStudent(index): Promise<any> {
    const result = await this.saveComment()
    if (result !== 'success') return
    while (!this.data[index]) {
      index--
    }
    if (index === -1) return
    const student = this.data[index]

    // this.setDetail(student, this.editTeacher, index, false)
    this.setDetail(student, this.type, index, false)
  }

  private validateIsFirst(index): boolean {
    do {
      index--
      if (index === -1) {
        return true
      }
    } while (!this.data[index])
    return false
  }

  private validateIsLast(index): boolean {
    do {
      index++
      if (index === this.data.length) {
        return true
      }
    } while (!this.data[index])
    return false
  }

  @Watch('viewerType')
  private onTypeChange(newVal): void {
    this.data = []
    this.teachers = []
    this.filter.optionId = undefined
    this.getGradePeriods(this.filter.schoolYearId)
  }

  private exportConduct(): void {
    this.exportLoading = true
    let optionId = this.filter.optionId ? this.filter.optionId : undefined
    let courseId = this.filter.courseId[1] ? this.filter.courseId[1] : undefined
    MonthlyGradeController.getBehaviorTable(this.filter.gradePeriodId[1], optionId, courseId)
      .then(async res => {
        let result = res.data.map(item => {
          let {
            lastName,
            enName,
            firstName,
            name,
            studentNum,
            grade,
            advisory,
            className,
            dormitory,
            selfStudyRoom,
            housePoint,
            conductPoint,
            schoolYearHousePoint,
            schoolYearConductPoint,
          } = item
          return {
            lastName,
            enName,
            firstName,
            name,
            studentNum,
            grade,
            advisory,
            className,
            dormitory,
            selfStudyRoom,
            housePoint: housePoint || 0,
            conductPoint: conductPoint || 0,
            schoolYearHousePoint: schoolYearHousePoint || 0,
            schoolYearConductPoint: schoolYearConductPoint || 0,
          }
        })

        const file = exportXlsx(this.excelColumns, result)
        const worksheet = file.getWorksheet(1)

        worksheet.eachRow(row => {
          row.height = 22
          row.eachCell(cell => {
            cell.border = {
              top: { style: 'thin', color: { argb: 'FF000000' } },
              left: { style: 'thin', color: { argb: 'FF000000' } },
              bottom: { style: 'thin', color: { argb: 'FF000000' } },
              right: { style: 'thin', color: { argb: 'FF000000' } },
            }
            cell.style.alignment = { vertical: 'middle', horizontal: 'center' }
          })
        })

        const parentGradePeriod = this.gradePeriods.find(
          item => item.key === this.filter.gradePeriodId[0]
        )
        const gradePeriod = parentGradePeriod.subOptions.find(
          item => item.key === this.filter.gradePeriodId[1]
        )

        await writeFile(file, `学生品行表 ${gradePeriod.enValue || gradePeriod.value}.xlsx`)
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        this.exportLoading = false
      })
  }
}
