
























import { Component, Vue, Watch } from 'vue-property-decorator'
// import CommentViewer from '@/view/ReportMgmt/Comment/components/CommentViewer.vue'
import CommentViewer from './components/CommentViewer.vue'
import { LoginController } from '@/services/request.service'

@Component({
  components: {
    CommentViewer,
  },
})
export default class Comment extends Vue {
  private emptyAuth: any = false
  private menuAuth: any = []

  private get type(): any {
    return this.$route.params.commentType || ''
  }

  private created(): void {
    this.getUserAuths()
  }

  private getUserAuths(): void {
    LoginController.getUserInfo().then(res => {
      this.menuAuth = res.data.dataPermission || []
    })
  }

  @Watch('menuAuth', { deep: true, immediate: true })
  private onAuthChange(value): void {
    this.emptyAuth = false
    if (
      (this.type === 'tutor' &&
        !(
          value.classTeacher ||
          value.sectionTeacher ||
          value.houseGroupTeacher ||
          value.houseTeacher
        )) ||
      this.type === 'noAuth'
    ) {
      this.redirectTo(value)
    }
  }

  private redirectTo(value): void {
    this.emptyAuth = false
    if (
      value.classTeacher ||
      value.sectionTeacher ||
      value.houseGroupTeacher ||
      value.houseTeacher
    ) {
      this.$router
        .replace({ name: 'monthlyComment', params: { commentType: 'advisor' } })
        .catch(err => {})
    } else if (value.deputyHead) {
      this.$router
        .replace({ name: 'monthlyComment', params: { commentType: 'deputyHead' } })
        .catch(err => {})
    } else {
      this.$router
        .replace({ name: 'monthlyComment', params: { commentType: 'noAuth' } })
        .catch(err => {})
      this.emptyAuth = true
    }
  }

  @Watch('$route', { immediate: true })
  private onRouteChange(to): void {
    if (to.name === 'monthlyComment' && !to.params.commentType) {
      this.redirectTo(this.menuAuth)
    } else if (to.params.commentType === 'noAuth') {
      this.emptyAuth = true
    }
  }

  private changeType({ item, key, keyPath }): void {
    this.$router.push({ name: 'monthlyComment', params: { commentType: key } }).catch(err => {})
  }
}
